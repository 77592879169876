.dashboard_body__3q2AW {
  width: 100vw;
  height: 100vh;
}
.unauthorized_body__uupVp {
  width: 100vw;
  height: 100vh;
}
html {
  margin: 0px
}

body {
  margin: 0px
}
